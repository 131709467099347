<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分统计</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
                    <el-form-item style="display:flex;" label="发放时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px; display:flex;" label="发放员工：">
                        <el-input  :maxlength="50" style="width:300px;" v-model="seachForm.username" placeholder="请输入发放员工" clearable></el-input>
                    </el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
        <div class="jifen_top"><p>积分统计：<span>{{integralCount}}</span></p></div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="releaseTime" label="发放时间" ></el-table-column>
				<el-table-column prop="username" label="发放员工" ></el-table-column>
				<el-table-column prop="phone" label="发放手机号" ></el-table-column>
				<el-table-column prop="releaseIntegral" label="发放积分" ></el-table-column>
				<el-table-column prop="operatorUser" label="操作用户" ></el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		employeeList,employeeEditUpdate,employeeUndock,integralList,excelExport
	} from "@/api/platform/index";
	export default {
		name: "employeeList",
		data() {
			return {
				dateBetween:'',
                seachForm:{
					username: '',
					beginTime: '',
					endTime: '',
				},
                sizeForm:{
                    id:'',
                    username:'',
                    phone:'',
                    password:'',
                    jobNumber:'',
                    email:''
                },
                integralCount:'0.00',
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addshow: false,

			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integralList({
                    username:this.seachForm.username,
                    beginTime:this.seachForm.beginTime,
                    endTime:this.seachForm.endTime,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.integralListVOIPage.records;
					this.integralCount = res.data.integralCount != null ? res.data.integralCount  : '0.00';
					this.total = res.data.integralListVOIPage.total;
					this.currentPage = res.data.integralListVOIPage.current;
					this.pageCount = res.data.integralListVOIPage.pages;
					if (res.data.integralListVOIPage.current > res.data.integralListVOIPage.pages && res.data.integralListVOIPage.pages != 0) {
						this.page = res.data.integralListVOIPage.pages;
						this.list();
					}
				})
			},
            
            // 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.seachForm.beginTime = '';
                    this.seachForm.endTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.seachForm.beginTime = datetime;
                this.seachForm.endTime = datetime1;
            },

            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

            //编辑按钮点击
			updataclick(i,tab){
				this.sizeForm = {
					id: tab[i].id,
					username:tab[i].username,
					phone: tab[i].phone,
					password: tab[i].password,
					jobNumber: tab[i].jobNumber,
					email: tab[i].email,
				}
				this.addshow = true
			},

			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id:'',
					username:'',
					phone:'',
					password:'',
					jobNumber:'',
					email:''
				}
				this.addshow = false
			},

             //编辑提交
			onUpdata(formName){
				let that = this;
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
							if(that.$refs[formName].model.username != ''){
								if(that.$refs[formName].model.phone != ''){
								    if(that.$refs[formName].model.password  != null && that.$refs[formName].model.password.length > 0){
                                        employeeEditUpdate({
                                            id:that.$refs[formName].model.id,
                                            username:that.$refs[formName].model.username,
                                            phone:that.$refs[formName].model.phone,
                                            password:that.$refs[formName].model.password,
                                            jobNumber:that.$refs[formName].model.jobNumber,
                                            email:that.$refs[formName].model.email
                                        }).then(res=>{
                                            if (res.code == 200) {
                                                that.$message.success('编辑成功');
                                                that.sizeForm = {
                                                    jobNumber:'',
                                                    email:'',
                                                    password: '',
                                                    phone: '',
                                                    username: '',
                                                    id:''
                                                }
                                                that.addshow = false;
                                                that.page = 1
                                                that.list();
                                            } else {
                                                that.$message.error(res.msg);
                                            }
                                        }).catch(err => {
                                            that.$message.error(err.msg);
                                        })
                                    }else{
									    that.$message.warning('密码不能为空');
								    }
								}else{
									that.$message.warning('手机号不能为空');
								}
							}else{
								that.$message.warning('员工名称不能为空');
							}
						} else {
							return false;
						}
					});
				}
			},

			//冻结/恢复/取消入驻
			deleteList(cur, tab) {
				this.$confirm('你确定要移除员工【' + tab[cur].username + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					employeeUndock(tab[cur].id ).then(res => {
						if (res.code == 200) {
							this.$message.success('操作成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(err => {
						this.$message.error(err.msg);
					})
				})
			},

            // 下载模板
            downLoad(){

                // var url = "https://jfsc.java.minshangkeji.com.cn/api/api/platform/excel/download";
                // this.downFile(url,'模板');
                // this.downFile(url,'logo.jpg');
                // excelDownload({}).then(res => {
                //     // this.downFile(res);
				// 	this.data = res.data.records;
				// 	this.total = res.data.total;
				// 	this.currentPage = res.data.current;
				// 	this.pageCount = res.data.pages;
				// 	if (res.data.current > res.data.pages && res.data.pages != 0) {
				// 		this.page = res.data.pages;
				// 		this.list();
				// 	}
				// })
            },

            beforeUpload(file) {
				// 文件类型
				const isType = file.type === 'application/vnd.ms-excel'
				const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				const fileType = isType || isTypeComputer
				if (!fileType) {
					this.$message.error('上传文件只能是xls/xlsx格式！')
				}
				// 文件大小限制为2M
				const fileLimit = file.size / 1024 / 1024 < 2
				if (!fileLimit) {
					this.$message.error('上传文件大小不超过2M！')
				}
				return fileType && fileLimit
			},
			
			// 自定义上传（此方法我打印不出任何数据，注释了也不影响---）
			uploadOk(param) {
				console.log(`上传成功---`, param)
				const fileFormData = new FormData()
				fileFormData.append('uploadFile', param.file)
				console.log(`上传参数---`, param.file)
				crudUser.uploadExcel(fileFormData).then(res => {
				    console.log(`上传成功？？？---`, res)
				})
			},

			// excel表上传（方法调用在此方法中）
			uploadExcel(file) {
				const fileTemp = file.raw
				const fileName = file.raw.name
				const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
				// 判断上传文件格式
				if (fileTemp) {
					if ((fileType === 'xlsx') || (fileType === 'xls')) {
                        const formData = new FormData()
                        // formData.append('uploadFile', file.raw)
                        // console.log(formData);
                        // return;
                        excelExport({fileTemp}).then(res => {
                            this.$message({
                                message: res,
                                type: 'success'
                            })
                        })
					} else {
                        this.$message({
                            type: 'warning',
                            message: '附件格式错误，请重新上传！'
                        })
					}
				} else {
					this.$message({
                        type: 'warning',
                        message: '请上传附件！'
					})
				}
			}
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.down_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.down_box p{
    font-size: 14px;
    color: blue;
    cursor: pointer;
    margin-right: 20px;
}
.jifen_top{
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    display: flex;
    align-content: center;
    justify-items: flex-start;
}
.jifen_top span{
    margin-right: 20px;
    font-weight: bold;
}
</style>
